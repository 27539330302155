<template>
  <div>
    <b-row>
      <b-col
        xl="9"
        md="9"
        sm="12"
      >
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>

          <b-row>

            <!-- User Info: Left col -->
            <b-col
              cols="21"
              xl="12"
              class="d-flex justify-content-between flex-column"
            >
              <!-- User Avatar & Action Buttons -->
              <div class="d-flex justify-content-start">
                <div class="d-flex flex-column text-truncate">
                  <div class="d-flex flex-wrap">
                    <b-button
                      :to="{ name: 'alerts-edit', params: { id: alert.uuid } }"
                      variant="primary"
                    >
                      Modifier
                    </b-button>
                    <b-button
                      v-b-modal="`modal-delete-alert-${alert.uuid}`"
                      :disabled="deleteLoading"
                      variant="outline-danger"
                      class="ml-0 ml-sm-1"
                    >
                      Supprimer
                    </b-button>
                    <b-modal
                      :id="`modal-delete-alert-${alert.uuid}`"
                      :ref="`modal-delete-alert-${alert.uuid}`"
                      ok-variant="danger"
                      ok-title="Supprimer"
                      modal-class="modal-danger"
                      centered
                      title="Suppression de l'alerte"
                    >
                      <b-card-text>
                        Souhaitez vous vraiment supprimer cette alerte ?<br>
                        <p class="text-danger font-weight-bold">
                          (CETTE ACTION EST IRRÉVERSIBLE)
                        </p>
                      </b-card-text>
                      <template #modal-footer>
                        <b-button
                          variant="secondary"
                          @click="hideModal()"
                        >
                          annuler
                        </b-button>
                        <b-button
                          variant="danger"
                          :disabled="deleteLoading"
                          @click="deleteAlert()"
                        >
                          <b-spinner
                            v-show="deleteLoading"
                            small
                          />
                          supprimer
                        </b-button>
                      </template>
                    </b-modal>
                  </div>
                </div>
              </div>

              <!-- alerte Stats -->
              <b-row class="mt-2">
                <b-col xl="12">
                  <b-row>
                    <b-col xl="10">
                      <div class="d-flex align-items-center">
                        <b-avatar
                          variant="light-primary"
                          rounded
                        >
                          <feather-icon
                            icon="AlignLeftIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            {{ alert.text.fr }}
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col xl="2">
                      <p>fr</p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col xl="10">
                      <div class="d-flex align-items-center">
                        <b-avatar
                          variant="light-primary"
                          rounded
                        >
                          <feather-icon
                            icon="AlignLeftIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            {{ alert.text.en }}
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col xl="2">
                      <p>en</p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col xl="10">
                      <div class="d-flex align-items-center">
                        <b-avatar
                          variant="light-primary"
                          rounded
                        >
                          <feather-icon
                            icon="AlignLeftIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            {{ alert.text.de }}
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col xl="2">
                      <p>de</p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col xl="10">
                      <div class="d-flex align-items-center">
                        <b-avatar
                          variant="light-primary"
                          rounded
                        >
                          <feather-icon
                            icon="AlignLeftIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            {{ alert.text.it }}
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col xl="2">
                      <p>it</p>
                    </b-col>
                  </b-row>
                  <b-row class="mt-1">
                    <b-col xl="10">
                      <div class="d-flex align-items-center">
                        <b-avatar
                          variant="light-primary"
                          rounded
                        >
                          <feather-icon
                            icon="AlignLeftIcon"
                            size="18"
                          />
                        </b-avatar>
                        <div class="ml-1">
                          <h5 class="mb-0">
                            {{ alert.text.es }}
                          </h5>
                        </div>
                      </div>
                    </b-col>
                    <b-col xl="2">
                      <p>es</p>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row class="mt-2">
                <b-col xl="12">
                  <b-row>
                    <b-col xl="2">
                      <div class="d-flex align-items-center">
                        <div class="colorBox" :style="{'background-color': alert.color_text}"></div>
                        <div class="ml-1">
                          couleur du texte
                        </div>
                      </div>
                    </b-col>
                    <b-col xl="4">
                      <div class="d-flex align-items-center">
                        <div class="colorBox" :style="{'background-color': alert.color_background}"></div>
                        <div class="ml-1">
                          couleur du fond
                        </div>
                      </div>
                    </b-col>
                    <b-col xl="2">
                      <div class="d-flex align-items-center">
                        <b-badge :variant="(alert.is_visible ? 'light-success' : 'light-danger')">
                          {{ alert.is_visible }}
                        </b-badge>
                        <div class="ml-1">
                          Visibilité
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col
        xl="3"
        md="3"
        sm="12"
      >
        <b-card no-body>
          <b-table
            :items="alert.platforms"
            :fields="tableColumns"
            :sticky-header="true"
            :no-border-collapse="true"
          >
            <template #cell(name)="data">
              <b-media vertical-align="center">
                <template #aside>
                  <b-avatar
                    size="32"
                    :src="data.item.favicon"
                    :text="avatarText(data.item.name)"
                    :to="{ name: 'platforms-view', params: { id: data.item.uuid } }"
                  />
                </template>
                <b-link
                  :to="{ name: 'platforms-view', params: { id: data.item.uuid } }"
                  class="font-weight-bold d-block text-nowrap"
                >
                  {{ data.item.name }}
                </b-link>
              </b-media>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BLink,
  BRow,
  BCol,
  BAvatar,
  BButton,
  BSpinner,
  BBadge,
  BTable,
  BMedia, BModal, BCardText,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { mapGetters } from 'vuex'
import { avatarText } from '@core/utils/filter'
import axiosIns from '@/libs/axios'

export default {
  name: 'AlertsView',
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BAvatar,
    BButton,
    BSpinner,
    BBadge,
    BTable,
    BMedia,
    BModal,
    BCardText,
  },
  setup() {
    const resolveStatusVariant = status => {
      if (status === 'PENDING') return 'light-info'
      if (status === 'RUNNING') return 'light-success'
      if (status === 'FINISHED') return 'light-warning'
      return 'light-success'
    }
    const resolveBooleanVariant = bool => (bool ? 'light-success' : 'light-danger')
    return {
      avatarText,
      resolveStatusVariant,
      resolveBooleanVariant,
    }
  },
  data() {
    return {
      deleteLoading: false,
      loading: true,
      tableColumns: [
        {
          key: 'name',
          label: 'Plateformes',
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      alert: 'alerts/alert',
    }),
  },
  mounted() {
    this.fetchAlert()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', { pageTitle: '', breadcrumb: [] })
  },
  methods: {
    async fetchAlert() {
      try {
        this.loading = true
        await this.$store.dispatch('alerts/fetchAlert', this.$route.params.id)
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: 'alerte',
          breadcrumb: [
            {
              text: 'Alertes',
              active: false,
              to: {
                name: 'alerts',
              },
            },
            {
              text: 'alerte',
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async deleteAlert() {
      try {
        this.deleteLoading = true
        await axiosIns.delete(`alerts/${this.alert.uuid}`)
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'L\'alerte a été supprimée',
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
        this.hideModal()
        await this.$router.push({
          path: '/alerts',
        })
      } catch (err) {
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
        this.deleteLoading = false
      }
    },
    hideModal() {
      this.$refs[`modal-delete-alert-${this.alert.uuid}`].hide()
    },
    changePage(e) {
      this.timelineCurrentPage = e
      this.fetchTimeline()
    },
  },
}
</script>

<style scoped>
.colorBox{
  height: 20px;
  width: 20px
}
</style>
